import * as React from "react"

import FadeLink from "../components/fade-link"
import Hero from "../components/hero"

const underline = {
    textDecoration: "underline"
}

const ContactPage = () => {
    const email="mailto:"
    const route = "contact"
    const at = "@"
    const site = "burtonpeake.co.uk"
    return (
        <Hero colour={"warning"}>
            <div className="content has-text-centered">
                <div className="title is-size-2-desktop">
                    CONTACT US
                </div>
                <p>
                    To contact us, <a style={underline} href={email+route+at+site}>send us an email</a> or connect with us via our <span style={underline}><FadeLink to="/team">LinkedIn pages</FadeLink></span>.
                </p>
            </div>
        </Hero>
    )
}

export default ContactPage
